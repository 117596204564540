$('.loader').removeClass('show')

import navigation from './navigation'
import home from './home'
import page from './page'
import contact from './contact'

navigation()
home()
page()
contact()

AOS.init({
  duration: 700
});