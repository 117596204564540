export default function page_init() {
  $('.prepage-information').click(function (e) {
    document.cookie = "prepage_information=true; path=/; max-age=31536000";
  })

  $('#content img').each(function() {
    if (!$(this).parent().is('a')) {
      const imgSrc = $(this).attr('src');
      const imgSrcSet = $(this).attr('srcset');
      let firstSrc = imgSrc;

      if (imgSrcSet) {
        const srcArray = imgSrcSet.split(',');
        const firstSrcSet = srcArray[0].trim();
        const srcUrl = firstSrcSet.split(' ')[0];
        firstSrc = srcUrl;
      }

      const $a = $('<a>');
      $a.attr('href', firstSrc);
      $a.attr('data-fancybox', 'page');

      $(this).wrap($a);
    }
  });

  $('.zabieg-slider').each(function() {
    $(this).slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
    })
  })

  const scrollTop = $('.scrollTop')
  scrollTop.click(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })

  var throttled = _.throttle(() => {
    if(window.scrollY > 100) {
      scrollTop.addClass('show')
    } else {
      scrollTop.removeClass('show')
    }
    statsAnimation()
  }, 100);

  $(window).scroll(throttled)

  $('.gallery.gallery-size-thumbnail').each(function() {
    const el = $(this)
    const galleryName = el.attr('id')
    const images = el.find('.gallery-item a')
    images.attr('data-fancybox', galleryName)
    images.fancybox()

  })

  const stats = $('.stats .stat')

  function statsAnimation() {
    stats.each((index, el) => {
      const offset = el.getBoundingClientRect()
      const offsetY = offset.y + (offset.height / 2) - (window.innerHeight * 0.1)
      const windowHeight = window.innerHeight * 0.9
      if(offsetY > 0 && offsetY < windowHeight) {
        const percent = Math.abs(100 - (offsetY / windowHeight * 100) - 50) * 0.02
        $(el).css("--_hide", percent)
      }
    })
  }
  statsAnimation()
}