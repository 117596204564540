export default function navigation_init() {
  const nav = $('.nav')

  $('.mobile-nav-toggle').click(() => {
    nav.toggleClass('expanded')
  })

  $(window).scroll(() => {
    if(window.scrollY > 100) {
      nav.addClass('scrolled')
    } else {
      nav.removeClass('scrolled')
    }
  })

  $('.megamenu .dropdown > a').click(function(e) {
    const item = $(this).parent()
    const hasClass = item.hasClass('expanded')
    $('.megamenu .dropdown').removeClass('expanded')
    if(!hasClass) item.addClass('expanded')

    e.preventDefault()
  })

  $('.nav-list > .dropdown > .nav-link').click(function(e) {
    const item = $(this).parent()
    const hasClass = item.hasClass('expanded')
    $('.nav-list .dropdown').removeClass('expanded')
    if(!hasClass) item.addClass('expanded')

    e.preventDefault()
  })

  function getGrid(item) {
    return $(item).parent().parent().parent().find('.menu-grid')[0]
  }

  function scanMenuItems(grid) {
    const items = []
    $(grid).find('.menu-section').each(function() {
      const section = $(this)
      const title = section.find('.title')[0]
      const sectionLevel2 = []
      const sectionLevel3 = []

      section.find('.menu-list > ul > li').each(function() {
        const item = $(this)
        const link = item.find(' > a')[0]
        const itemLevel2 = []

        item.find('.submenu-list > ul > li').each(function() {
          const subitem = $(this)
          const sublink = subitem.find(' > a')[0]
          items.push({
            tree: [section, item, subitem],
            level: 3,
            value: sublink.innerText.toLowerCase()
          })
          itemLevel2.push(subitem)
        })

        items.push({
          tree: [section, item, ...itemLevel2],
          level: 2,
          value: link.innerText.toLowerCase()
        })
        sectionLevel2.push(item)
        sectionLevel3.push(...itemLevel2)
      })

      if(title.innerText !== '') {
        items.push({
          tree: [section, ...sectionLevel2, ...sectionLevel3],
          level: 1,
          value: title.innerText.toLowerCase(),
        })
      }
    })
    return items
  }

  function searchMenuItems(el) {
    if(el.value.length < 3) return null
    const menu = megamenus.find(x => x.input === el)
    const filtered = [...menu.items].filter(x => x.value.includes(el.value.toLowerCase()))
    return filtered
  }

  function filterMenuItems(filtered, megamenu) {
    const gridEl = $(megamenu.grid)
    gridEl.find('.show').removeClass('show')

    if(filtered === null) {
      gridEl.removeClass('filter')
      return
    }


    gridEl.addClass('filter')

    if(filtered.length === 0) {
      gridEl.find('.no-result').addClass('show')
      return
    }

    gridEl.find('.no-result').removeClass('show')
    filtered.map(item => {
      item.tree.map(el => {
        el.addClass('show')
      })
    })
  }

  const megamenus = []
  $('.megamenu .input-search').focus(function() {
    if(!megamenus.find(x => x.input === this)) {
      const grid = getGrid(this)
      const megamenu = $($(grid).parent()[0])
      const searchForm = megamenu.find('.search-form')[0]
      const clearButton = megamenu.find('.button-clear')[0]
      megamenus.push({
        input: this,
        form: searchForm,
        clearButton: clearButton,
        grid: grid,
        items: scanMenuItems(grid)
      })
    }
  })

  const onInput = _.debounce(function() {
    const searchForm = $(this).parent()
    if(this.value == '') {
      searchForm.removeClass('not-empty')
    } else {
      searchForm.addClass('not-empty')
    }
    const megamenu = megamenus.find(x => x.input === this)
    const filteredItems = searchMenuItems(this)
    filterMenuItems(filteredItems, megamenu)
  }, 500)

  $('.dropdown:not(.search) .megamenu .input-search').on("input", onInput)
  $('.dropdown:not(.search) .megamenu .search-form').on('submit', function(e) {
    e.preventDefault()
    const megamenu = megamenus.find(x => x.form === this)
    if(!megamenu) return
    const filteredItems = searchMenuItems(megamenu.input)
    $(megamenu.input).focus()
    filterMenuItems(filteredItems, megamenu)
  })
  $('.dropdown:not(.search) .megamenu .button-clear').click(function() {
    const megamenu = megamenus.find(x => x.clearButton === this)
    megamenu.input.value = ''
    $(megamenu.form).removeClass('not-empty')
    $(megamenu.input).focus()
    filterMenuItems(null, megamenu)
  })

  function fetchSearch(megamenu) {
    if(!megamenu) return
    if(megamenu.input.value == '') {
      $(megamenu.grid).removeClass('loading')
      megamenu.grid.innerHTML = '<li class="no-result show">Brak wyników wyszukiwania</li>'
      return
    }
    const searchUrl = megamenu.form.dataset.searchUrl
    const phrase = encodeURIComponent(megamenu.input.value)
    const fetchUrl = `${searchUrl}${phrase}`
    const searchForm = $(megamenu.form)
    megamenu.grid.classList.add('loading')
    $.get( fetchUrl, {format: 'json'}, function( data ) {
      const jsonData = JSON.parse(data)
      let html = ''
      jsonData.forEach(element => {
        html += `<li class="menu-section"><a href="${element.link}">${element.title}<div class="excerpt">${element.excerpt}</div></a></li>`
      })
      if(html === '') html = '<li class="no-result show">Brak wyników wyszukiwania</li>'
      megamenu.grid.classList.remove('loading')
      megamenu.grid.innerHTML = html

      if(megamenu.input.value == '') {
        searchForm.removeClass('not-empty')
      } else {
        searchForm.addClass('not-empty')
      }
    })

    if(megamenu.input.value == '') {
      searchForm.removeClass('not-empty')
    } else {
      searchForm.addClass('not-empty')
    }

  }


  const onInputFetch = _.debounce(function() {
    const megamenu = megamenus.find(x => x.input === this)
    if(!megamenu) return
    fetchSearch(megamenu)
  }, 500)

  $('.dropdown.search .megamenu .input-search').on("input", onInputFetch)
  $('.dropdown.search .megamenu .search-form').on('submit', function(e) {
    e.preventDefault()
    const megamenu = megamenus.find(x => x.form === this)
    if(!megamenu) return
    $(megamenu.input).focus()
    fetchSearch(megamenu)
  })
  $('.dropdown.search .megamenu .button-clear').click(function() {
    const megamenu = megamenus.find(x => x.clearButton === this)
    megamenu.input.value = ''
    $(megamenu.form).removeClass('not-empty')
    $(megamenu.input).focus()
    fetchSearch(megamenu)
  })

}